<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          支付通道
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <!--按钮组-->
          <b-button variant="primary" @click="create()" class="mx-2">
            <b-icon icon="plus"></b-icon>
            新增
          </b-button>

          <!--按钮组-->
          <b-button variant="success" @click="edit()" class="mx-2">
            <b-icon icon="pencil"></b-icon>
            编辑
          </b-button>
          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <!--表格-->
          <v-data-table show-select fixed-header dense height="70vh" v-model="table.selected" :headers="table.headers"
            :items="table.desserts" :single-select="table.singleSelect" :footer-props="table.footer"
            :server-items-length="table.itemsCount" :options.sync="table.pagination" :no-data-text="table.noDataText"
            :loading="table.loading" :loading-text="table.loadingText" item-key="channelId">
            <template v-slot:item.webSiteUrl="{ item }">
              <a :href="item.webSiteUrl" target="_blank">
                {{ item.webSiteUrl }}</a>
            </template>

            <template v-slot:item.status="{ item }">
              <v-switch class="mt-0 pt-0" v-model="item.status" :true-value="1" :false-value="0"
                @change="onSwitchStatus(item)" color="success" hide-details></v-switch>
            </template>

            <template v-slot:item.updateTime="{ item }">
              {{ item.updateTime | formatDate }}
            </template>

            <!--状态字段   格式处理-->
            <template v-slot:item.action="{ item }">
              <v-btn small text icon fab v-b-tooltip.hover.lefttop title="编辑" v-on:click="edit(item.channelId)">
                <v-icon>la la-edit</v-icon>
              </v-btn>

              <!-- <v-btn small text icon fab v-b-tooltip.hover.lefttop title="禁用" v-if="item.status == 1"
                v-on:click="changeStatus(item, 0)">
                <v-icon>icon-xl la la-lock</v-icon>
              </v-btn>

              <v-btn small text icon fab v-b-tooltip.hover.lefttop title="启用" v-else-if="item.status == 0"
                v-on:click="changeStatus(item, 1)">
                <v-icon>icon-xl la la-lock-open</v-icon>
              </v-btn> -->
            </template>

            <!--表格筛选-->
            <template v-slot:top>
              <b-form inline>
                <b-form-group>
                  <b-form-select :options="filter.conditions" v-model="filter.field"></b-form-select>
                </b-form-group>
                <label class="after-label ml-2 pb-md-2 pb-lg-2 pb-xl-2 pb-sm-3 pb-3">:</label>
                <b-form-group class="mx-2">
                  <b-form-input id="input-2" v-model="filter.keyword" placeholder="检索关键词"></b-form-input>
                </b-form-group>

                <b-form-group class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0">
                  <b-form-select :options="filter.statusOptions" v-model="filter.status"></b-form-select>
                </b-form-group>

                <b-form-group class="mx-2">
                  <b-button variant="success" v-on:click="getData()">
                    <v-icon small> flaticon-search</v-icon>
                    查询
                  </b-button>
                </b-form-group>
              </b-form>

              <!--分隔线-->
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </div>
      </template>
    </KTCard>

    <!--模态框-数据表单-->
    <b-modal ref="my-modal" size="lg" centered title="支付网关编辑" static="true">
      <b-container fluid>
        <b-form id="payChannelForm" method="POST" class="form" novalidate="novalidate">
          <b-row>
            <b-col cols="6">
              <b-form-group label="通道名称:">
                <b-form-input type="text" name="channelName" v-model="formModel.channelName" placeholder="请输入通道名称...">
                </b-form-input>
              </b-form-group>

              <b-form-group label="联系电话:">
                <b-form-input type="text" name="tel" v-model="formModel.telPhone" placeholder="请输入联系电话...">
                </b-form-input>
              </b-form-group>

              <b-form-group label="通道管理后台地址:">
                <b-form-input type="text" name="wwebSiteUrlebUrl" v-model="formModel.webSiteUrl"
                  placeholder="请输入通道管理后台地址..."></b-form-input>
              </b-form-group>


            </b-col>
            <b-col cols="6">
              <b-form-group label="联系人:">
                <b-form-input type="text" name="contactsUser" v-model="formModel.contactsUser" placeholder="请输入联系人...">
                </b-form-input>
              </b-form-group>

              <b-form-group label="联系人QQ:">
                <b-form-input type="text" name="qq" v-model="formModel.qq" placeholder="请输入联系人QQ..."></b-form-input>
              </b-form-group>

              <b-form-group label="状态:">
                <v-radio-group class="mt-0" row name="status" v-model="formModel.status" :mandatory="false">
                  <v-radio label="正常" :value="1"></v-radio>
                  <v-radio label="禁用" :value="0"></v-radio>
                </v-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      <!--表格筛选-->
      <template v-slot:modal-footer>
        <b-button v-on:click="onClose" variant="danger">取消</b-button>
        <b-button v-on:click="onSubmit" variant="primary">保存</b-button>
      </template>
    </b-modal>
    <!--模态框-数据表单-->
  </div>
</template>

<script>
import Vue from "vue";

import KTCard from "@/view/content/Card.vue";
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";

export default {
  name: "List",
  components: {
    KTCard,
  },
  props: {
    title: String,
  },
  data() {
    return {
      filter: {
        conditions: [{
          value: null,
          text: "检索条件"
        },
        {
          value: "channelName",
          text: "通道名称"
        },
        {
          value: "telPhone",
          text: "联系电话"
        },
        {
          value: "qq",
          text: "联系QQ"
        },
        ],
        statusOptions: [{
          value: null,
          text: "状态"
        },
        {
          value: 0,
          text: "禁用"
        },
        {
          value: 1,
          text: "正常"
        },
        ],
        field: null,
        keyword: "",
        status: null,
      },
      submitUrl: "",
      formModel: {
        channelId: "",
        name: "",
        contacts: "",
        tel: "",
        qq: "",
        webUrl: "",
        sort: "",
        status: "",
        createTime: "",
        updateTime: "",
      },
      table: {
        loadingText: "正在加载...",
        noDataText: "暂无数据...",
        loading: false,
        singleSelect: false,
        selected: [],
        itemsCount: 10,
        headers: [{
          text: "通道ID",
          value: "channelId"
        },
        {
          text: "通道名称",
          value: "channelName"
        },
        {
          text: "联系人",
          value: "contactsUser"
        },
        {
          text: "联系电话",
          value: "telPhone"
        },
        {
          text: "联系人QQ",
          value: "qq"
        },
        {
          text: "通道管理后台",
          value: "webSiteUrl"
        },
        {
          text: "状态",
          value: "status"
        },
        {
          text: "更新时间",
          value: "updateTime"
        },
        {
          text: "操作",
          value: "action"
        },
        ],
        footer: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          itemsPerPageText: "分页数量",
          showCurrentPage: true,
        },
        pagination: {
          itemsPerPage: 20,
        },
        desserts: [],
      },
    };
  },
  mounted() {
    // Create a FormValidation instance
    let payChannelForm = KTUtil.getById("payChannelForm");
    this.fv = formValidation(payChannelForm, {
      fields: {
        channelName: {
          validators: {
            notEmpty: {
              message: "通道名称不能为空!",
            },
          },
        },
        contactsUser: {
          validators: {
            notEmpty: {
              message: "联系人不能为空!",
            },
          },
        },
        telPhone: {
          validators: {
            notEmpty: {
              message: "联系电话不能为空!",
            },
          },
        },
        qq: {
          validators: {
            notEmpty: {
              message: "联系人QQ不能为空!",
            },
          },
        },
        webSiteUrl: {
          validators: {
            notEmpty: {
              message: "通道管理后台地址不能为空!",
            },
          },
        },

        status: {
          validators: {
            notEmpty: {
              message: "请选择状态!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });
  },
  created() { },

  methods: {
    create() {
      this.submitUrl = "/api/channel/manage/create";
      this.formModel = {};

      this.$refs["my-modal"].show();
    },
    edit(id = undefined) {
      this.submitUrl = "/api/channel/manage/update";
      id = id == undefined ? this.getSelectedIds() : [id];
      if (id.length != 1) {
        Swal.fire({
          title: "",
          text: "请选择要编辑的数据行,一次仅且只能选择一条!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }

      let that = this;
      ApiService.query("/api/channel/manage/query_by_id", {
        params: {
          channelId: id[0],
        },
      }).then(({
        data
      }) => {
        that.formModel = data.result;
      });

      this.$refs["my-modal"].show();
    },

    onSwitchStatus(item) {
      let that = this;
      ApiService.post("/api/channel/manage/update", {
        channelId: item.channelId,
        status: item.status,
      }).then(({
        data
      }) => {
        if (data.success) {
          Swal.fire({
            title: "",
            text: "修改成功！",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 1500,
          });
          that.getData();
        } else {
          Swal.fire({
            title: "修改失败！",
            text: data.message,
            icon: "warning",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 3000,
          });
        }
      });
    },
    // changeStatus(item, status) {
    //   if (item == null || item == undefined) {
    //     Swal.fire({
    //       title: "",
    //       text: "请选择要处理的数据行!",
    //       icon: "warning",
    //       confirmButtonClass: "btn btn-success",
    //       heightAuto: false,
    //     });
    //     return;
    //   }
    //   let that = this;
    //   ApiService.post("/api/channel/manage/update", {
    //     channelId: item.channelId,
    //     status: status,
    //   }).then(({
    //     data
    //   }) => {
    //     if (data.success) {
    //       Swal.fire({
    //         title: "",
    //         text: "修改成功！",
    //         icon: "success",
    //         confirmButtonClass: "btn btn-success",
    //         heightAuto: false,
    //         timer: 1500,
    //       });
    //       that.getData();
    //     } else {
    //       Swal.fire({
    //         title: "修改失败！",
    //         text: data.message,
    //         icon: "warning",
    //         confirmButtonClass: "btn btn-success",
    //         heightAuto: false,
    //         timer: 3000,
    //       });
    //     }
    //   });
    // },

    getData() {
      let params = {
        pageNum: this.table.pagination.page,
        pageSize: this.table.pagination.itemsPerPage,
        status: this.filter.status,
      };
      params["" + this.filter.field + ""] = this.filter.keyword;

      ApiService.query("/api/channel/manage/query_list", {
        params: params,
      }).then(({
        data
      }) => {
        this.table.desserts = data.result.data;
        this.table.itemsCount = data.result.total;
      });
    },

    getSelectedIds() {
      let ids = [];
      if (this.table.selected != null && this.table.selected != undefined) {
        this.table.selected.forEach(function (item) {
          ids.push(item.channelId);
          console.info(ids);
        });
      }
      return ids;
    },

    onClose() {
      this.$refs["my-modal"].hide();
    },

    onSubmit() {
      let that = this;
      this.fv.validate().then(function (status) {
        if (status === "Valid") {
          ApiService.post(that.submitUrl, that.formModel).then(({
            data
          }) => {
            if (data.success) {
              Swal.fire({
                title: "",
                text: "保存成功！",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 1500,
              });
              that.getData();
              that.$refs["my-modal"].hide();
            } else {
              Swal.fire({
                title: "保存失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000,
              });
            }
          });
        }
      });
    },
  },
  computed: {
    pagination: function () {
      return this.table.pagination;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>